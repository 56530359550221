import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));

// if ('serviceWorker' in navigator) {
//   navigator.serviceWorker.register('firebase-messaging-sw.js')
//     .then((registration) => {
//       console.log('fireBase service worker',registration)
//     })
//     .catch((error) => {
//       console.log('fireBase service worker error', error)
//     });
// }

import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from '../../../../../environments/environment';
import {Company} from '../../pages/requests/interfaces/requests.interface';
import {
  Child,
  ChildReq,
  CreateManager,
  Customer,
  PersonalData,
  PersonalDataReq,
  User,
  UserDetailsInformation, ValidationFioReq,
} from '../interfaces/users.interface';
import {_DeepPartialObject, DeepPartial} from "chart.js/dist/types/utils";
import {CustomerTypeEnum} from "../../../shared/enums/customer.enum";
import {FIO} from "../../../shared/services/tools.service";

@Injectable()
export class UsersService {
  constructor(private http: HttpClient) {
  }

  getUser(id: string): Observable<User> {
    return this.http.get<User>(
      `${environment.apiUrl}/users/crm/get-user-by-id/${id}`
    );
  }

  getMe(): Observable<User> {
    return this.http.get<User>(`${environment.apiUrl}/users/get-me`);
  }

  getAllCustomers(): Observable<Customer[]> {
    return this.http.get<Customer[]>(
      `${environment.apiUrl}/users/crm/get-all-customers`
    );
  }

  createCustomer(phone: string, personalData?: PersonalDataReq, userDetailsInformation?: _DeepPartialObject<UserDetailsInformation>): Observable<Customer> {
    return this.http.post<Customer>(
      `${environment.apiUrl}/users/create-customer`, {phone, personalData, userDetailsInformation}
    );
  }

  updateCustomer(customerId: string, data: DeepPartial<Customer>): Observable<Customer> {
    return this.http.post<Customer>(`${environment.apiUrl}/users/update-customer/${customerId}`, data)
  }

  updateUser(userId: string, data: DeepPartial<User>): Observable<User> {
    return this.http.post<User>(`${environment.apiUrl}/users/update-user/${userId}`, data);
  }

  updateUserDetails(userId: string, data: Partial<UserDetailsInformation>): Observable<User> {
    return this.http.post<User>(`${environment.apiUrl}/users/update-user-details/${userId}`, data);
  }

  getCustomer(customerId: string): Observable<Customer> {
    return this.http.get<Customer>(
      `${environment.apiUrl}/users/crm/get-full-customer-by-user-id/${customerId}`
    );
  }

  removeUser(userId: string): Observable<string> {
    return this.http.delete<string>(`${environment.apiUrl}/users/delete-user/${userId}`);
  }

  addCompanyToUser(customerId: string, data: Company): Observable<Customer> {
    return this.http.post<Customer>(
      `${environment.apiUrl}/users/add-company-to-user/${customerId}`,
      data
    );
  }

  createChild(data: ChildReq): Observable<Child> {
    return this.http.post<Child>(`${environment.apiUrl}/users/create-child`, data);
  }

  getChild(childrenId: string): Observable<Child> {
    return this.http.get<Child>(`${environment.apiUrl}/users/get-child/${childrenId}`);
  }

  getChildren(): Observable<Child[]> {
    return this.http.get<Child[]>(`${environment.apiUrl}/users/get-children`);
  }

  updateChild(childId: string, data: DeepPartial<ChildReq>): Observable<Child> {
    return this.http.post<Child>(`${environment.apiUrl}/users/update-child/${childId}`, data);
  }

  removeChild(childId: string): Observable<string> {
    return this.http.delete<string>(`${environment.apiUrl}/users/delete-child/${childId}`);
  }

  // addPersonToUser(
  //   customerId: string,
  //   data: AddPersonReq
  // ): Observable<Customer> {
  //   return this.http.post<Customer>(
  //     `${environment.apiUrl}/users/add-person-to-user/${customerId}`,
  //     data
  //   );
  // }

  getParticipant(userId: string, participantId?: string): Observable<PersonalData> {
    return this.http.post<PersonalData>(`${environment.apiUrl}/users/get-participant-by-options`, {
      userId,
      participantId: participantId ?? null
    });
  }

  createManager(data: CreateManager): Observable<User> {
    return this.http.post<User>(
      `${environment.apiUrl}/users/create-manager/`,
      data
    );
  }

  getAllManagers(): Observable<User[]> {
    return this.http.get<User[]>(`${environment.apiUrl}/users/crm/get-all-managers`);
  }

  getAllManagersPersonalData() {
    return this.http.get<Customer[]>(`${environment.apiUrl}/users/crm/get-all-managers-personal-data`);
  }

  uploadPassportScan(file: File, customerId: string) {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);

    return this.http.post(`${environment.apiUrl}/users/upload-passport-scan/${customerId}`, formData);
  }

  uploadChildPassportScan(file: File, childId: string) {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);

    return this.http.post(`${environment.apiUrl}/users/upload-child-passport-scan/${childId}`, formData);
  }

  getCustomerTypeText(type?: CustomerTypeEnum) {
    switch (type) {
      case CustomerTypeEnum.Default:
        return 'Не выбран'
      case CustomerTypeEnum.Bodium:
        return 'Bodium'
      default:
        return 'Не выбран'
    }
  }

  setPromoCode(promoCode: string, customerId: string): Observable<Customer> {
    return this.http.post<Customer>(`${environment.apiUrl}/users/set-promocode`, {
        promoCode,
        customerId
      }
    )
  }

  validateCustomerFio(data: ValidationFioReq) {
    return this.http.post<boolean>(`${environment.apiUrl}/users/validate-fio`, data)
  }
}

export const environment = {
  production: false,
  socketUrl: 'https://api-dev.getluxe.ru',
  apiUrl: 'https://api-dev.getluxe.ru/api',
  // socketUrl: 'https://api-dev.getluxe.ru',
  // apiUrl: 'https://api-dev.getluxe.ru/api',
  // socketUrl: 'http://localhost:3000',
  // apiUrl: 'http://localhost:3000/api',
  dadataApiKey: '886834d553a6a295b100d51230642b7efc23ae56',
  dadataSecretKey: 'ebf935d2434834f71ddbe37c451c8bddb8340fa3',
  firebase: {
    apiKey: "AIzaSyA0u2EY9kr1TRBQJT7YiUpQzqB5ajk61kU",
    authDomain: "getluxe-5b56c.firebaseapp.com",
    projectId: "getluxe-5b56c",
    storageBucket: "getluxe-5b56c.appspot.com",
    messagingSenderId: "944140357440",
    appId: "1:944140357440:web:17a5c0dedc0786582734a5",
    measurementId: "G-5CH6K2LMTB",
    vapidKey: 'BJKH94AnLUDlfzR4wV_w3LbfFkunxy5jxgvkae5Wp5kxwz_F82KGfYK4GxKX9Ho6CTiZgbYrPQnIZP2h2JFWt8k'
  }
};


import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { SystemUserService } from '../system-user.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard  {
  constructor(private systemUser: SystemUserService, private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    if (this.systemUser.getAccessToken()) return of(true);

    this.router.navigate(['/auth/login'], {
      queryParams: {
        accessDenied: true,
      },
    });
    return of(false);
  }

  canActivateChild = (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> => this.canActivate(route, state);
}

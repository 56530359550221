import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../environments/environment";
import {Observable} from "rxjs";
import {IRequestPush, IResponsePush} from "../interfaces/push.interface";

@Injectable()
export class AppService {
  constructor(
    private http: HttpClient
  ) {
  }

  public addToken(data: IRequestPush): Observable<IResponsePush> {
    return this.http.post<IResponsePush>(
      `${environment.apiUrl}/push-notification/register-token`,
      data
    );
  }
}

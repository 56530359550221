import {APP_INITIALIZER, LOCALE_ID, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MatIconModule} from "@angular/material/icon";
import {IconsService} from "./services/icons.servcie";
import {HTTP_INTERCEPTORS, HttpClientModule} from "@angular/common/http";
import {TokenInterceptor} from "./modules/shared/services/guards/token.interceptor";
import {MatSnackBarModule} from "@angular/material/snack-bar";
import {AuthService} from "./modules/auth/services/auth.service";

import {DATE_PIPE_DEFAULT_OPTIONS, registerLocaleData} from "@angular/common";
import localeRu from '@angular/common/locales/ru';
import {UsersService} from "./modules/platform/shared/services/users.service";

registerLocaleData(localeRu, 'ru');

import {environment} from "../environments/environment";
import {initializeApp} from "firebase/app";
import {AppService} from "./services/app.service";
import {NgxMaskDirective, NgxMaskPipe, provideEnvironmentNgxMask} from 'ngx-mask';
import {MAT_DATE_LOCALE} from "@angular/material/core";
import {appInitializer} from "./modules/shared/factories/init.factory";

initializeApp(environment.firebase);

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatIconModule,
    HttpClientModule,
    NgxMaskDirective,
    NgxMaskPipe,
    MatSnackBarModule,
  ],
  providers: [
    {provide: LOCALE_ID, useValue: 'ru'},
    {
      provide: DATE_PIPE_DEFAULT_OPTIONS, useValue: {timezone: 'GMT+0300'}
    },
    {provide: MAT_DATE_LOCALE, useValue: 'ru-RU'},
    {
      provide: HTTP_INTERCEPTORS,
      multi: true,
      useClass: TokenInterceptor,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializer,
      multi: true,
      deps: [IconsService]
    },
    IconsService,
    provideEnvironmentNgxMask(),
    AuthService,
    UsersService,
    AppService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
}

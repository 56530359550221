import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, tap} from 'rxjs';
import {environment} from '../../../../environments/environment';
import {PersistenceService} from '../../shared/services/persistence.service';
import {
  CrmSendSmsReq,
  CrmSendSmsRes,
  LoginReq,
  LoginRes,
} from '../interfaces/auth.interface';
import {SystemUserService} from "../../shared/services/system-user.service";
import {StoredUser} from "../../../interfaces/app.interface";

@Injectable()
export class AuthService {
  public currentUser$= new BehaviorSubject<StoredUser>(null!);
  public access_token$ = new BehaviorSubject<string>('');

  constructor(
    private systemUserService: SystemUserService,
    private http: HttpClient,
    private persistenceService: PersistenceService
  ) {
  }

  public async initAuthServiceVariables(): Promise<void> {
    this.currentUser$.next(this.systemUserService.getCurrentUser())
    this.access_token$.next(this.systemUserService.getAccessToken())
    console.log('inited auth')
  }

  public crmSendSms(data: CrmSendSmsReq): Observable<CrmSendSmsRes> {
    return this.http.post<CrmSendSmsRes>(
      `${environment.apiUrl}/auth/crm-send-sms`,
      data,
      {headers: {Anonymous: 'true'}}
    );
  }

  public login(data: LoginReq): Observable<LoginRes> {
    return this.http
      .post<LoginRes>(`${environment.apiUrl}/auth/login`, data, {
        headers: {Anonymous: 'true'},
      })
      .pipe(
        tap(({access_token, ...data}) => {
          this.currentUser$.next({
            access_token,
            ...data
          })
          this.persistenceService.set('user', data);
          this.persistenceService.set('accessToken', access_token);
        })
      );
  }
}

import {catchError, EMPTY, Observable, of, throwError} from 'rxjs';
import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Router} from '@angular/router';
import {SystemUserService} from '../system-user.service';
import {environment} from "../../../../../environments/environment";
import {MatSnackBar} from '@angular/material/snack-bar';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(
    private router: Router,
    private systemUserService: SystemUserService,
    private snackBar: MatSnackBar
  ) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    const accessToken = this.systemUserService.getAccessToken();

    if (!req.headers.get('Anonymous')) {
      if (accessToken) {
        if (req.url.includes(`${environment.apiUrl}`)) {
          req = req.clone({
            setHeaders: {
              Authorization: `Bearer ${accessToken}`,
            },
          });
        }
        return next.handle(req).pipe(
          catchError((error: HttpErrorResponse) => {
            // Обработка ошибки здесь
            console.error('Произошла ошибка:', error);

            // Вывод сообщения об ошибке
            this.snackBar.open(`Произошла ошибка: ${error?.error?.message ?? error?.error?.error ?? error?.error}`, '', {
              horizontalPosition: 'right',
              verticalPosition: 'top',
              duration: 2500,
            });

            return of()
          })
        );
      } else {
        this.systemUserService.logout();
        this.router.navigate(['/auth'], {
          queryParams: {
            sessionFailed: true,
          },
        });
        return EMPTY;
      }
    } else {
      return next.handle(req);
    }
  }
}

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { PersistenceService } from './persistence.service';
import { StoredUser } from 'src/app/interfaces/app.interface';

@Injectable({
  providedIn: 'root'
})
export class SystemUserService {
  constructor(
    private router: Router,
    private persistenceService: PersistenceService
  ) {}

  getCurrentUser = (): StoredUser => this.persistenceService.get('user');

  getAccessToken = (): string => this.persistenceService.get('accessToken');

  logout(b: boolean = true) {
    localStorage.clear();
    if (b) this.router.navigate(['/auth']);
  }
}

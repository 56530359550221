import {Component, OnInit} from '@angular/core';
import {IconsService} from "./services/icons.servcie";
import {AppStore} from "./services/app.store";
import {AuthService} from "./modules/auth/services/auth.service";
import {UsersService} from "./modules/platform/shared/services/users.service";
import {tap} from "rxjs";
import {PersistenceService} from "./modules/shared/services/persistence.service";
import {environment} from "../environments/environment";
import {getMessaging, getToken} from "firebase/messaging";
import {AppService} from "./services/app.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  constructor(
    private iconsService: IconsService,
    private appStore: AppStore,
    private authService: AuthService,
    private usersService: UsersService,
    private persistenceService: PersistenceService,
    private appService: AppService,
  ) {
    this.authService.initAuthServiceVariables();
  }

  requestPermission() {
    const messaging = getMessaging();
    Notification.requestPermission().then(permission => {
      console.log('Notification.requestPermission', permission)
      if (permission === 'granted') {
        getToken(messaging,
          {vapidKey: environment.firebase.vapidKey}).then(
          (currentToken) => {
            if (currentToken) {
              this.appService.addToken({token: currentToken}).subscribe();
            } else {
              console.log('No registration token available. Request permission to generate one.');
            }
          }).catch((err) => {
          console.log('An error occurred while retrieving token. ', err);
        });
      }
    });
  }

  listen() {
    // const messaging = getMessaging();
    // onMessage(messaging, (payload) => {
    //
    //   const notificationTitle = payload.notification?.title;
    //   const notificationOptions = payload.notification
    //   const data: any = payload.data
    //
    //   const notify = new Notification(notificationTitle ?? '', notificationOptions)
    //   // notify.addEventListener('click', () => {
    //   //   notify.close()
    //   //   window.open(data?.url)
    //   // })
    // });
  }

  ngOnInit() {

    this.requestPermission()
    this.listen()

    this.usersService.getMe().pipe(
      tap(user => this.persistenceService.set('user', user))
    ).subscribe()
    this.appStore.sidebarGroups$.next([
      {
        open: true,
        name: 'CRM',
        links: [
          {name: 'Дешборд', link: '/platform/dashboard'},
          {name: 'События', link: '/platform/events'},
          {name: 'Заявки', link: '/platform/requests'},
          {name: 'Архивные заявки', link: '/platform/archive-requests'},
          {name: 'Клиенты', link: '/platform/clients'},
          {name: 'Дети', link: '/platform/children'},
          {name: 'Организации', link: '/platform/customer-organization'},
          {name: 'Спец-предложения', link: '/platform/special-offers'},
          {name: 'Мероприятия', link: '/platform/arrangements'},
          // {name: 'Типы заметки', link: '/platform/task-notes-types'},
        ]
      },
      {
        open: false,
        name: 'Системная информация',
        links: [
          {name: 'Типы услуг', link: '/platform/service-types'},
          {name: 'Платёжные методы', link: '/platform/payment-methods'},
          {name: 'Поставщики', link: '/platform/suppliers'},
          // {name: 'База знаний', link: '/platform/25'},
          // {name: 'Статистика', link: '/platform/35'},
          {name: 'Пользователи', link: '/platform/users'},
          {name: 'Аналитика', link: '/platform/analytics', isAdmin: true},
          {name: 'Мобильная аналитика', link: '/platform/mobile-analytics'},
          {name: 'Версия приложений', link: '/platform/mobile-version'},
          {name: 'Логи', link: '/platform/logs'},
          // {name: 'Подборки', link: '/platform/35'},
          // {name: 'Скидки и предложения', link: '/platform/35'},
          // {name: 'Новости', link: '/platform/35'},
        ]
      },
      {
        open: false,
        name: 'Бухгалтерия',
        links: [
          // {name: 'Постоянные затраты', link: '/platform/accounting'},
          {name: 'Фин. операции', link: '/platform/accounting'},
        ]
      },
      // {
      //   open: false,
      //   name: 'Финансы',
      //   links: [
      //     {name: 'События', link: '/platform/www'},
      //     {name: 'Заявки', link: '/platform/25'},
      //     {name: 'Клиенты', link: '/platform/35'}
      //   ]
      // },
      // {
      //   open: false,
      //   name: 'Разное',
      //   links: [
      //     {name: 'События', link: '/platform/www'},
      //     {name: 'Заявки', link: '/platform/25'},
      //     {name: 'Клиенты', link: '/platform/35'}
      //   ]
      // }
    ])
  }
}

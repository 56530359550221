  import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable()
export class IconsService {
  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer
  ) {}

  public icons: string[] = [
    'logo',
    'bell',
    'small-triangle',
    'chevron-left',
    'chevrons-left',
    'chevrons-right',
    'chevron-right',
    'x',
    'long-arrow',
    'news',
    'circle-one',
    'circle-two',
    'next-arrow',
    'chevron-down',
    'chevron-up',
    'arrow-left',
    'search',
    'check-mark',
    'more-horizontal',
    'plus',
    'check',
    'arrow-up',
    'edit',
    'alert-circle',
    'message-circle',
    'filter',
    'download-cloud',
    'calendar',
    'resize',
    'meh',
    'ticket',
    'edit-2',
    'refresh-cw',
    'baggage-claim',
    'bed-double',
    'file-image',
    'car',
    'file-plus',
    'file',
    'pizza',
    'palmtree',
    'utensils',
    'maximize-2',
    'trash',
    'view',
    'send',
    'just-check',
    'check-check',
    'paperclip',
    'log-out',
    'camera',
    'upload',
    'person-standing',
    'download',
    'cake',
    'arrow-right',
    'book',
    'play',
    'pause',
    'microphone',
    'user-check',
    'package-check',
    'plane',
    'package',
    'user',
    'dollar-sign',
    'sort-asc',
    'sort-desc'
  ];

  public initIcons(): void {
    this.icons.forEach((name) =>
      this.matIconRegistry.addSvgIcon(
        name,
        this.domSanitizer.bypassSecurityTrustResourceUrl(`assets/icons/${name}.svg`)
      )
    );
  }
}

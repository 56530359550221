import {Injectable} from "@angular/core";
import {BehaviorSubject} from "rxjs";
import {ISidebarGroup} from "../interfaces/app.interface";

@Injectable({
  providedIn: 'root'
})
export class AppStore {
  public sidebarGroups$: BehaviorSubject<ISidebarGroup[]> = new BehaviorSubject<ISidebarGroup[]>([])
}
